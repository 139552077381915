import React from "react";

import CheckCircleIcon from "components/Icons/circle_check_mark.svg";

export const badgeTypes = Object.freeze({
  VERIFIED_SHOPPER: "verified_shopper",
});

const getBadgeContent = (badgeType: string) => {
  const badgeMap = {
    [badgeTypes.VERIFIED_SHOPPER as string]: {
      icon: <CheckCircleIcon width="15" height="16" />,
      text: "Verified Shopper",
    },
  };
  return badgeMap[badgeType];
};

const CardBadge = ({ type }: { type: string }) => {
  const badgeContent = getBadgeContent(type);
  if (!badgeContent) {
    return null;
  }
  return (
    <div className="flex icon--centered">
      {badgeContent.icon}
      <div className="text-xs uppercase font-bold ml-xs">
        {badgeContent.text}
      </div>
    </div>
  );
};

export default CardBadge;
