import consumerApi from "api/services/consumer-api";
import logError from "utils/logError";

const deleteReviewVote = async (
  reviewId: string | number,
): Promise<boolean> => {
  try {
    await consumerApi.delete(`api/review_votes/v1/${reviewId}`);

    return true;
  } catch (e) {
    logError(e.message, {
      functionName: "deleteReviewVote",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return false;
  }
};

export default deleteReviewVote;
