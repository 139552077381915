import React from "react";
import classNames from "classnames";

type Props = {
  className?: string;
  hide?: boolean;
  hideMd?: boolean;
  hideLg?: boolean;
  onClick?: (e: React.MouseEvent, isExpanded?: boolean) => void;
  showLessText: string;
  showMoreText: string;
  //string for applying a secondary color to button gradient
  theme?: "white" | "leafly-white";
  //bool for applying transform to arrow onClick
  toggleContainer?: boolean;
  buttonAriaLabel?: string;
};

const ExpandableContainerBtn: React.FC<Props> = ({
  className,
  hide,
  hideMd,
  hideLg,
  onClick,
  showLessText,
  showMoreText,
  theme = "white",
  toggleContainer,
  buttonAriaLabel = "See more",
}) => {
  const leaflyWhite = theme === "leafly-white" ? "--leafly-white" : "";
  const btnHiddenClasses = classNames(
    { "expandable-container__button--hidden": !!hide },
    { "md:expandable-container__button--hidden": !!hideMd },
    { "lg:expandable-container__button--hidden": !!hideLg },
  );
  const btnClasses = classNames(
    "expandable-container__button",
    btnHiddenClasses,
    { [`expandable-container__button--open${leaflyWhite}`]: toggleContainer },
    {
      "expandable-container__button--leafly-white": theme === "leafly-white",
    },
    className,
  );

  return (
    <button
      className={btnClasses}
      onClick={onClick}
      aria-label={buttonAriaLabel}
    >
      <span className="mt-md">
        {toggleContainer ? showLessText : showMoreText}
      </span>
    </button>
  );
};

export default ExpandableContainerBtn;
