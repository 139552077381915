import React, { useState } from "react";
import classNames from "classnames";

import ThumbUpOutlineIcon from "components/Icons/thumb_up_outline.svg";
import ThumbUpSolidIcon from "components/Icons/thumb_up_solid.svg";

import ReportReviewModal from "./ReportReviewModal";

type ReviewCardFooterProps = {
  hasReported?: boolean;
  hasUserVoted?: boolean;
  reportAction?: () => void;
  upvoteCount?: number;
  voteAction?: () => void;
};

const ReviewCardFooter: React.FC<ReviewCardFooterProps> = ({
  hasReported,
  hasUserVoted,
  reportAction,
  upvoteCount,
  voteAction,
}) => {
  const helpfulLabel = `${upvoteCount} ${
    upvoteCount === 1 ? `person` : `people`
  } found this helpful`;

  const [showReportReviewModal, setShowReportReviewModal] = useState(false);

  return (
    <div className="modal-background">
      <footer className="dispensary-review-card__footer block">
        {upvoteCount !== undefined && upvoteCount !== 0 && (
          <span className="font-normal text-secondary">{helpfulLabel}</span>
        )}
        <div
          className={classNames(
            {
              "pt-sm": upvoteCount === 0,
            },
            "flex justify-between",
          )}
        >
          {voteAction && (
            <button
              onClick={voteAction}
              className="icon--centered text-xs font-bold tertiary underline"
            >
              {hasUserVoted ? (
                <ThumbUpSolidIcon height="20" width="20" />
              ) : (
                <ThumbUpOutlineIcon height="20" width="20" />
              )}
              <span className="pl-xs">helpful</span>
            </button>
          )}
          {reportAction &&
            (hasReported ? (
              <span className="text-xs font-bold text-error">reported</span>
            ) : (
              <button
                onClick={() => {
                  setShowReportReviewModal(true);
                }}
                className="text-xs font-bold underline"
              >
                report
              </button>
            ))}
        </div>
      </footer>

      {showReportReviewModal && (
        <ReportReviewModal
          reportAction={reportAction}
          onDismissed={() => setShowReportReviewModal(false)}
        />
      )}
    </div>
  );
};

export default ReviewCardFooter;
