import React from "react";

import Placeholder, { Rect } from "components/Placeholder";

const ReviewCardPlaceholder: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div className={`p-lg relative ${className}`}>
      <div className="flex justify-between border-b border-light-grey mb-lg pb-lg">
        <Placeholder width="100" height="44">
          <Rect width="65" height="14" />
          <Rect y="20" width="100" height="22" />
        </Placeholder>
        <Placeholder width="90" height="16">
          <Rect width="100%" height="100%" />
        </Placeholder>
      </div>
      <div className="mb-lg">
        <Placeholder width="100%" height="114">
          <Rect width="100%" height="18" />
          <Rect y="23" width="100%" height="18" />
          <Rect y="46" width="100%" height="18" />
          <Rect y="69" width="80%" height="18" />
          <Rect y="100" width="150" height="14" />
        </Placeholder>
      </div>
      <div className="flex justify-between">
        <Placeholder width="65" height="20">
          <Rect width="100%" height="100%" />
        </Placeholder>
        <Placeholder width="40" height="20">
          <Rect width="100%" height="100%" />
        </Placeholder>
      </div>
    </div>
  );
};

export default ReviewCardPlaceholder;
