import { differenceInDays } from "date-fns";

const DATE_LABEL_MAP: Record<number, string> = Object.freeze({
  0: "Today",
  1: "Yesterday",
  2: "2 days ago",
  3: "3 days ago",
  4: "4 days ago",
  5: "5 days ago",
  6: "6 days ago",
  7: "a week ago",
});

/**
 * Converts a Date into a string such as "Yesterday", "N days ago", up to a "a week ago"
 */
export const toTimeAgoFormat = (date: Date, { now = new Date() } = {}) => {
  if (
    Object.prototype.toString.call(date) !== "[object Date]" ||
    isNaN(date as unknown as number)
  ) {
    return "";
  }

  const dayDifference = differenceInDays(now, date);

  return (
    DATE_LABEL_MAP[dayDifference] ||
    date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
  );
};
