import React from "react";

import EffectIcon from "components/Icons/EffectIcon";

export const EFFECT_ICON_MAPPING = Object.freeze({
  ANXIOUS: { icon: "anxious", label: "Anxious" },
  AROUSED: { icon: "aroused", label: "Aroused" },
  CREATIVE: { icon: "creative", label: "Creative" },
  DIZZY: { icon: "dizzy", label: "Dizzy" },
  "DRY EYES": { icon: "dry-eyes", label: "Dry eyes" },
  "DRY MOUTH": { icon: "dry-mouth", label: "Dry mouth" },
  ENERGETIC: { icon: "energetic", label: "Energetic" },
  EUPHORIC: { icon: "euphoric", label: "Euphoric" },
  FOCUSED: { icon: "focused", label: "Focused" },
  GIGGLY: { icon: "giggly", label: "Giggly" },
  HAPPY: { icon: "happy", label: "Happy" },
  HEADACHE: { icon: "headache", label: "Headache" },
  HUNGRY: { icon: "hungry", label: "Hungry" },
  RELAXED: { icon: "relaxed", label: "Relaxed" },
  SLEEPY: { icon: "sleepy", label: "Sleepy" },
  TALKATIVE: { icon: "talkative", label: "Talkative" },
  TINGLY: { icon: "tingly", label: "Tingly" },
  UPLIFTED: { icon: "uplifted", label: "Uplifted" },
});

type ReviewReportedFeelingProps = {
  /**
   * The feelings reported in the review (e.g. "Euphoric", "Hungry")
   */
  feeling: string;
};

const ReviewReportedFeeling: React.FC<ReviewReportedFeelingProps> = ({
  feeling,
}) => {
  const { label, icon } =
    EFFECT_ICON_MAPPING[
      feeling.toUpperCase() as keyof typeof EFFECT_ICON_MAPPING
    ] || {};

  return (
    <>
      {label && icon && (
        <div className="pb-xs px-sm text-xs font-bold icon--centered">
          <EffectIcon
            className="pr-xs flex-shrink-0"
            effect={icon}
            height="25"
            width="25"
          />
          {label}
        </div>
      )}
    </>
  );
};
export default ReviewReportedFeeling;
