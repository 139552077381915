import React from "react";
import classNames from "classnames";
import Link from "next/link";

import { toTimeAgoFormat } from "utils/toTimeAgoFormat";

import CardBadge from "components/botanic/Card/CardBadge";
import StarRating from "components/botanic/StarRating";

type ReviewCardHeaderProps = {
  badges?: { type: string }[];
  className?: string;
  children: React.ReactNode;
  date?: string;
  rating?: number;
  username: string;
  userPublicId?: string;
} & React.HTMLAttributes<HTMLElement>;

const ReviewCardHeader: React.FC<ReviewCardHeaderProps> = ({
  badges = [],
  className,
  children,
  date,
  rating,
  username,
  userPublicId,
  ...others
}) => {
  const obscuredUsername = `${username[0]}........${
    username[username.length - 1]
  }`;
  const classes = classNames(
    "border-b border-light-grey mb-lg pb-lg",
    className,
  );
  const href = `/profile/${userPublicId || username}`;

  return (
    <header className={classes} {...others}>
      <div className="pt-xs">
        <div
          className="flex justify-between"
          itemProp="reviewRating"
          itemScope
          itemType="https://schema.org/Rating"
        >
          <meta itemProp="worstRating" content="1" />
          <meta itemProp="ratingValue" content={`${rating}`} />
          <meta itemProp="bestRating" content="5" />

          <StarRating rating={rating} showRating={false} starWidth={13} />
          {date && (
            <div className="text-default text-xs">
              {toTimeAgoFormat(new Date(date))}
            </div>
          )}
        </div>
        <span itemProp="author" itemScope itemType="https://schema.org/Person">
          <span itemProp="name">
            <Link className="text-default pt-xs tracking-[2px]" href={href}>
              {obscuredUsername}
            </Link>
          </span>
        </span>
        {badges.length > 0 && (
          <div className="pt-xs text-deep-green">
            {badges.map((badge) => (
              <CardBadge key={badge.type} type={badge.type} />
            ))}
          </div>
        )}
        {children}
      </div>
    </header>
  );
};

export default ReviewCardHeader;
