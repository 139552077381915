import React, { useState } from "react";

type ReviewResponseProps = {
  /**
   * Text from retailer in response to a reviewer's comment.
   */
  response?: string;
  /**
   * Name of the store or dispensary responding. Defaults to "Dispensary"
   */
  retailerName: string;
};

const ReviewResponse: React.FC<ReviewResponseProps> = ({
  retailerName,
  response,
}) => {
  const [hasClickedResponse, setHasClickedResponse] = useState(false);

  const handleClickReviewResponse = () => {
    setHasClickedResponse(!hasClickedResponse);
  };

  if (!response || !response.trim().length) {
    return null;
  }

  return (
    <div>
      {hasClickedResponse ? (
        <div className="ml-xl mb-xs">
          <button
            className="font-bold text-xs text-secondary "
            onClick={handleClickReviewResponse}
          >
            {retailerName} said:
          </button>
          <p className="text-default mb-lg">{response}</p>
        </div>
      ) : (
        <button
          className="font-bold text-xs text-secondary pb-xs"
          onClick={handleClickReviewResponse}
        >
          {retailerName} replied
        </button>
      )}
    </div>
  );
};

export default ReviewResponse;
