import consumerApi from "api/services/consumer-api";
import logError from "utils/logError";

const postFlagReview = async (
  reviewId: string | number,
  reviewType: "dispensary" | "strain" | "product",
): Promise<boolean> => {
  try {
    const response = await consumerApi.post(
      `api/${reviewType}_reviews/v1/${reviewId}/flag`,
    );

    return [200, 201].includes(response.status);
  } catch (e) {
    logError(e.message, {
      functionName: "postFlagReview",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return false;
  }
};

export default postFlagReview;
