import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import throttle from "lodash/throttle";

import ExpandableContainerButton from "./ExpandableContainerButton";

type Props = {
  /**
   * enables automatic hiding of the dropdown button based
   * on the height of the child content via useEffect and the
   * hideButton state.
   */
  autoHide?: boolean;
  btnClass?: string;
  buttonAriaLabel?: string;
  children: React.ReactNode;
  className?: string;
  /**
   * used to set the initial state of container
   */
  expand?: boolean;
  /**
   * hides button at all breakpoints
   */
  hide?: boolean;
  /**
   * hides button at large (>1024px) breakpoint
   */
  hideLg?: boolean;
  /**
   * hides button at medium (>767px) breakpoint
   */
  hideMd?: boolean;
  /**
   * string for setting the initial max-height of unexpanded component
   */
  initialHeight?: string;
  /**
   * string for setting the max-height of expanded component
   */
  maxHeight?: string;
  /**
   * Click event that returns the event and boolean for the
   *expanded/unexpanded container state.
   */
  onButtonClick?: (e: React.MouseEvent, isExpanded?: boolean) => void;
  /**
   * string to replace the "Show less" button
   */
  showLessText?: string;
  /**
   * string to replace the "Show more" button
   */
  showMoreText?: string;
  /**
   * string for applying color to button gradient and initializing default container class
   */
  theme?: "white" | "leafly-white";
};

const ExpandableContainer: React.FC<Props> = ({
  autoHide,
  btnClass,
  className,
  children,
  expand = false,
  hide,
  hideMd,
  hideLg,
  initialHeight = "188px",
  maxHeight = "9999px",
  onButtonClick = () => {},
  showLessText = "Show less",
  showMoreText = "Show more",
  theme = "white",
  buttonAriaLabel = "See more",
}) => {
  const [expandedContent, setExpandedContent] = useState(expand);
  const [hideButton, setHideButton] = useState(true);
  const buttonRef = useRef<HTMLDivElement>(null);
  const shouldHide = autoHide ? hideButton : hide;

  useEffect(() => {
    //TODO: useReducer then finally apply useLayoutEffect and add current ref check
    if (!autoHide) {
      return;
    }
    const isChildHeightShort = throttle(() => {
      const childHeight = buttonRef?.current?.clientHeight || 0;
      const containerHeight = parseInt(initialHeight, 10);

      const newHideBtnState = childHeight < containerHeight;
      if (hideButton !== newHideBtnState) {
        setHideButton(newHideBtnState);
      }
    }, 200);

    window.addEventListener("resize", isChildHeightShort);
    isChildHeightShort();
    return function cleanup() {
      window.removeEventListener("resize", isChildHeightShort);
    };
  }, [autoHide, initialHeight, hideButton]);

  const classes = classNames(
    className,
    { "bg-white": theme === "white" },
    { "bg-leafly-white": theme === "leafly-white" },
  );
  const noBtnClasses = classNames(
    { "expandable-container__no-button": !!shouldHide },
    { "md:expandable-container__no-button": !!hideMd },
    { "lg:expandable-container__no-button": !!hideLg },
  );
  const containerClasses = classNames("expandable-container", noBtnClasses, {
    "expandable-container--open": expandedContent,
  });

  const toggleExpandContent = (e: React.MouseEvent) => {
    setExpandedContent(!expandedContent);
    onButtonClick(e, expandedContent);
  };

  return (
    <div className={classes}>
      <div
        ref={buttonRef}
        className={containerClasses}
        style={{
          // @ts-ignore. This can be fixed by refactoring,
          // but nothing is inherently wrong with this
          "--initial-container-height": `${initialHeight}`,
          "--max-container-height": `${maxHeight}`,
        }}
      >
        {children}
      </div>
      <ExpandableContainerButton
        className={btnClass}
        hide={shouldHide}
        hideMd={hideMd}
        hideLg={hideLg}
        onClick={toggleExpandContent}
        showLessText={showLessText}
        showMoreText={showMoreText}
        theme={theme}
        toggleContainer={expandedContent}
        buttonAriaLabel={buttonAriaLabel}
      />
    </div>
  );
};

export default ExpandableContainer;
