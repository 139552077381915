import React from "react";
import classNames from "classnames";

import ReviewReportedFeeling from "./ReviewReportedFeeling";

const FEELINGS_DISPLAY_LIMIT = 4;

type ReviewReportedFeelingsProps = {
  ariaLabel?: string;
  /**
   * Strain reported feelings
   */
  reportedFeelings?: string[];
  className?: string;
};

const ReviewReportedFeelings: React.FC<ReviewReportedFeelingsProps> = ({
  ariaLabel,
  reportedFeelings = [],
  className,
}) => {
  const classes = classNames(
    "flex flex-wrap flex-shrink-0 items-start pt-sm",
    className,
  );

  return (
    <>
      {reportedFeelings.length > 0 && (
        <div aria-label={ariaLabel} className={classes}>
          {reportedFeelings.slice(0, FEELINGS_DISPLAY_LIMIT).map((feeling) => (
            <ReviewReportedFeeling feeling={feeling} key={feeling} />
          ))}
        </div>
      )}
    </>
  );
};

export default ReviewReportedFeelings;
