import React from "react";
import classNames from "classnames";

import { DispensaryAddress } from "custom-types/DispensaryAddress";

import ExpandableContainer from "../ExpandableContainer";
import ReviewCardFooter from "./ReviewCardFooter";
import ReviewCardHeader from "./ReviewCardHeader";
import ReviewReportedFeelings from "./ReviewReportedFeelings";
import ReviewResponse from "./ReviewResponse";

/**
 * Review card
 *
 * Note: using this component requires mounting the ModalFrame component
 * in your application.
 */

type ReviewCardProps = {
  /**
   * List of badges
   */
  badges?: { type: string }[];
  className?: string;
  /**
   * Date the review was created
   */
  created?: string;
  /**
   * Has the user reported the review
   */
  hasReported?: boolean;
  /**
   * Has the user voted on this review
   */
  hasUserVoted?: boolean;
  /**
   * itemReviewed for search engine optimization; doesn't affect component appearance
   */
  itemReviewed?: string;
  /**
   * Star rating to display in header
   */
  rating: number;
  /**
   * page where ReviewCard is used, to support schema for different pages: currently strains, products or dispensaries
   */
  referringPageType?: string;
  /**
   * onClick handler for "Report" button
   */
  reportAction?: () => void;
  /**
   * Strain reported feelings
   */
  reportedFeelings?: string[];
  /**
   * Name of the store or dispensary responding. Defaults to "Dispensary"
   */
  retailerName?: string;
  /**
   * Text from retailer in response to a reviewer's comment.
   */
  reviewResponse?: string;
  /**
   * The review text. Long text will be truncated automatically.
   */
  text: string;
  /**
   * Determine if text should be truncated.
   */
  truncate?: boolean;
  /**
   * The number of upvotes a review has received
   */
  upvoteCount?: number;
  /**
   * The username of whoever wrote the review
   */
  username: string;
  /**
   * The public ID of the user, used for linking to a user's profile
   */
  userPublicId?: string;
  /**
   * Hide metadata when is not needed
   */
  hideMetadata?: boolean;
  /**
   * onClick handler for '👍 Helpful' button
   */
  voteAction?: () => void;
  /**
   * For localbusiness schema specific to dispensary pages
   */
  dispensaryAddress?: DispensaryAddress;
} & React.HTMLAttributes<HTMLElement>;

const ReviewCard: React.FC<ReviewCardProps> = ({
  badges = [],
  className,
  created,
  hasReported,
  hasUserVoted,
  itemReviewed,
  rating,
  referringPageType,
  reportAction,
  reportedFeelings = [],
  retailerName = "Retailer",
  reviewResponse,
  text,
  truncate = false,
  upvoteCount,
  username,
  userPublicId,
  voteAction,
  hideMetadata,
  dispensaryAddress,
  ...others
}) => {
  const classes = classNames("review-card shadow-low rounded", className);

  const ariaLabelReportedFeelings = () => {
    let text;
    if (reportedFeelings.length === 0 || !reportedFeelings) {
      text = "no reported feelings for this review";
    }
    if (reportedFeelings.length === 1) {
      text = `the reported feeling for this review is ${reportedFeelings[0].toLowerCase()}`;
    } else {
      text = "the reported feelings for this review are ";
      if (reportedFeelings.length === 2) {
        text = text.concat(
          `${reportedFeelings[0].toLowerCase()} and ${reportedFeelings[1].toLowerCase()}`,
        );
      } else {
        const lastFeeling = reportedFeelings.pop();
        const listOfFeelings = reportedFeelings
          .join(", ")
          .concat(` and ${lastFeeling}`);
        text = text.concat(listOfFeelings).toLowerCase();
      }
    }
    return text;
  };

  const itemTypeByPage = (referringPageType: string) => {
    if (
      referringPageType.includes("strain") ||
      referringPageType.includes("product")
    ) {
      return "https://schema.org/Product";
    } else if (referringPageType.includes("dispensary")) {
      return "https://schema.org/LocalBusiness";
    } else {
      return "";
    }
  };

  return (
    <section
      className={classes}
      {...others}
      {...(!hideMetadata
        ? {
            itemProp: "review",
            itemScope: true,
            itemType: "http://schema.org/Review",
          }
        : {})}
    >
      {!hideMetadata && itemReviewed && (
        <span
          itemProp="itemReviewed"
          itemScope
          itemType={itemTypeByPage(referringPageType || "")}
          data-testid="review-schema"
        >
          <meta itemProp="name" content={itemReviewed}></meta>
          <meta
            itemProp="author"
            itemType="https://schema.org/Person"
            content={username}
          ></meta>
          {referringPageType?.includes("dispensary") && dispensaryAddress && (
            <span
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
            >
              <meta
                itemProp="streetAddress"
                content={dispensaryAddress.address1 || ""}
              />
              <meta
                itemProp="addressLocality"
                content={dispensaryAddress.city || ""}
              />
              <meta
                itemProp="addressRegion"
                content={dispensaryAddress.state || ""}
              />
              <meta
                itemProp="postalCode"
                content={dispensaryAddress.zip || ""}
              />
              <meta
                itemProp="addressCountry"
                content={dispensaryAddress.country || ""}
              />
            </span>
          )}
        </span>
      )}

      {!hideMetadata && created && (
        <meta
          itemProp="datePublished"
          content={new Date(created).toISOString()}
        />
      )}

      <ReviewCardHeader
        badges={badges}
        date={created}
        rating={rating}
        username={username}
        userPublicId={userPublicId}
      >
        <ReviewReportedFeelings
          ariaLabel={ariaLabelReportedFeelings()}
          reportedFeelings={reportedFeelings}
          className="-ml-sm"
        />
      </ReviewCardHeader>
      <div className="flex justify-between">
        <div className="flex-grow">
          {truncate ? (
            <ExpandableContainer
              autoHide
              showMoreText="read full review"
              className="text-default"
              initialHeight="108px"
              btnClass="pb-lg mb-0"
            >
              <div className="mb-lg" itemProp="reviewBody">
                {text}
              </div>
            </ExpandableContainer>
          ) : (
            <div itemProp="reviewBody">{text}</div>
          )}

          <ReviewResponse
            retailerName={retailerName}
            response={reviewResponse}
          />
          <ReviewCardFooter
            hasReported={hasReported}
            hasUserVoted={hasUserVoted}
            reportAction={reportAction}
            upvoteCount={upvoteCount}
            voteAction={voteAction}
          />
        </div>
      </div>
    </section>
  );
};

export default ReviewCard;
