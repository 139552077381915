import React from "react";

import { Modal } from "components/botanic/Modal";

type Props = {
  reportAction?: () => void;
} & React.ComponentProps<typeof Modal>;

const ReportReviewModal: React.FC<Props> = ({ reportAction, ...props }) => (
  <Modal title="Report review" width={480} {...props}>
    {({ dismiss }) => (
      <>
        <div className="mt-xl text-sm flex justify-center">
          Are you sure you want to report this review?
        </div>
        <div className="flex justify-center mb-lg mt-md">
          <button
            className="button button--secondary px-lg mx-sm"
            style={{ width: "140px" }}
            onClick={(event) => {
              event.preventDefault();
              dismiss();
            }}
            aria-label="Hide this modal to report reviews"
            data-testid="report-review-close-button"
          >
            cancel
          </button>
          <button
            className="button px-lg mx-sm"
            style={{ width: "140px" }}
            onClick={(event) => {
              event.preventDefault();
              reportAction?.();
              dismiss();
            }}
            aria-label="Report review and close modal"
            data-testid="report-review-submit-button"
          >
            yes, report review
          </button>
        </div>
      </>
    )}
  </Modal>
);

export default ReportReviewModal;
