import consumerApi from "api/services/consumer-api";
import logError from "utils/logError";

const postReviewVote = async (reviewId: string | number): Promise<boolean> => {
  try {
    await consumerApi.post(`api/review_votes/v1/${reviewId}`);

    return true;
  } catch (e) {
    logError(e.message, {
      functionName: "postReviewVote",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return false;
  }
};

export default postReviewVote;
